<template>
  <v-layout row wrap class="my-12 mx-0" align-center>
    <v-flex xs12 class="mt-12">
      <div class="text-center mt-12">
        <div class="mt-11 mx-2 justify-center" style="display: grid">
          <h2
            class="font-weight-medium nightText"
            :class="
              this.$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'
            "
          >
            Miksi aurinkoenergia?
          </h2>
          <v-divider style="border-color: #004b55" class="mx-12 mt-5" />
        </div>
      </div>
    </v-flex>
    <v-flex xs12 class="my-0">
      <v-container>
        <v-layout row class="ma-0 text-center title">
          <v-flex xs12>
            Maailman kasvavan energiantarpeen nousu luo paineita
            energiantuotannon suhteen.
          </v-flex>
          <v-flex xs12>
            Uusiutuvia energialähteitä käyttämällä pystymme vähentämään
            fossiilisten energialähteiden käyttöä.
          </v-flex>
          <v-flex xs12>
            Jatkuvasti nousevat energiakulut nostavat asumiskustannuksia
          </v-flex>
          <v-flex xs12>
            Uusiutuva energia antaa hyvän mahdollisuuden siirtyä
            energiantuotossa enemmän omavaraiseksi
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "energy"
};
</script>
